import React, { useState } from "react"
import styled from "styled-components"

import CheckboxArray from "./CheckboxArray"

const Container = styled.div`
  margin-bottom: 0.5rem;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  text-align: left;
`
const Symbol = styled.div`
  margin-right: 0.5rem;
  flex-shrink: 0;

  &:before {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #fff;
    ${props => (props.visible ? "content: '-'" : "content: '+'")};
  }
`
const AccordingTitle = styled.span`
  display: inline-block;
  text-transform: uppercase;
`
const CheckboxContainer = styled.div`
  padding-left: 2rem;
`

const According = ({ title, options = [], onChange, value, ...rest }) => {
  const [visible, setVisible] = useState(false)
  const toggleVisibility = () => setVisible(!visible)

  return (
    <Container {...rest}>
      <TitleContainer onClick={toggleVisibility}>
        <Symbol visible={visible} />
        <AccordingTitle>{title}</AccordingTitle>
      </TitleContainer>
      <CheckboxContainer>
        {visible && (
          <CheckboxArray options={options} onChange={onChange} value={value} />
        )}
      </CheckboxContainer>
    </Container>
  )
}

export default According
