import React from "react"

/**
 * Implements a controlled checkbox with configurable
 * values to return through onChange callback prop.
 * @prop {any} checkedValue   Value to return as onChange callback argument
 *                            when it is checked
 * @prop {any} uncheckedValue Value to return as onChange callback argument
 *                            when it is unchecked
 * @prop {any} value          Current value. When it is equal to checkedValue
 *                            prop the checkbox is in checked state
 * @prop {Function} onChange  Callback function which called when the checkbox
 *                            checked state changes.
 */
class Checkbox extends React.Component {
  changeHandler = evt => {
    const { checkedValue = true, uncheckedValue = false, value } = this.props
    if (this.props.onChange) {
      this.props.onChange(
        value === checkedValue ? uncheckedValue : checkedValue
      )
    }
  }

  render() {
    const {
      checkedValue = true,
      uncheckedValue = false,
      readOnly = false,
      disabled = false,
      value = uncheckedValue,
    } = this.props
    return (
      <input
        onChange={this.changeHandler}
        type="checkbox"
        data-checked={value === checkedValue}
        disabled={readOnly || disabled}
      />
    )
  }
}

export default Checkbox
