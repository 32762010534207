import * as React from "react"
import styled from "styled-components/macro"
import Checkbox from "./Checkbox"
import isEmpty from "lodash.isempty"

const Checkmark = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: 1px solid #fff;
  margin-right: 3px;
  transition: background-color 0.2s ease-in-out;

  &:after {
    content: "";
    display: none;
    min-width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.layout === "horizontal" ? "row" : "column"};
  flex-wrap: wrap;

  label {
    display: flex;
    font-size: 0.77777778em;
    padding-right: ${props => (props.layout === "horizontal" ? "10px" : "0")};
    margin-bottom: ${props => (props.layout === "vertical" ? "5px" : "0")};

    &.container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      user-select: none;

      & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &:hover ${Checkmark} {
        background-color: rgba(255, 255, 255, 0.1);
      }

      & input[data-checked="true"] ~ ${Checkmark}:after {
        display: flex;
      }

      & ${Checkmark}:after {
        content: "+";
      }
    }

    & > div {
      display: flex;
      align-items: center;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }
`

/**
 * This class will render the given options as a CheckboxArray.
 * The component always expect an `onChange` function and a `value`
 * as an Array.
 *
 * @class CheckboxArray
 * @extends {React.Component<Props>}
 */
class CheckboxArray extends React.Component {
  constructor(props) {
    super(props)
    // store values in order as specified by options props
    this.orderedValues = (props.options || []).map(o => o.value)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options !== nextProps.options) {
      this.orderedValues = (nextProps.options || []).map(o => o.value)
    }
  }

  changeHandler = checkedValue => currentValue => {
    let result
    let { value } = this.props
    value = value || []
    if (checkedValue === currentValue) {
      result = [...value, checkedValue]
    } else {
      result = value.filter(v => v !== checkedValue)
      if (isEmpty(result)) {
        result = undefined
      }
    }
    // filter is used to maintain order
    // of values as specified by options props
    // filter and indexOf chosen because
    // these methods are common to ES6 array's and Immutable List's API
    this.props.onChange(
      result ? this.orderedValues.filter(v => result.indexOf(v) > -1) : result
    )
    this.props.onBlur && this.props.onBlur()
  }

  render() {
    const {
      layout = "horizontal",
      options = [],
      uncheckedValue = "",
      readOnly = false,
      disabled = false,
    } = this.props

    let { value = [] } = this.props

    value = value || []

    return (
      <div>
        <Container layout={layout}>
          {options.map(option => (
            <label className="container" key={option.value}>
              <Checkbox
                checkedValue={option.value}
                uncheckedValue={uncheckedValue}
                value={value.some(v => option.value === v) ? option.value : ""}
                onChange={this.changeHandler(option.value)}
                readOnly={readOnly}
                disabled={disabled}
              />
              <Checkmark />
              &nbsp;{option.label}
            </label>
          ))}
        </Container>
      </div>
    )
  }
}

export default CheckboxArray
