import React, { useState } from "react"
import styled from "styled-components"
import intersection from "lodash.intersection"
import { SkewLoader } from "react-spinners"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import { useHeroAssets } from "../hooks/hero"
import Shows, { buildCovesSrc } from "../components/sections/Shows"
import According from "../components/According"
import { useShows } from "../hooks/shows"

const LOCATION_OPTIONS = [
  { label: "kültér", value: "outside" },
  { label: "beltér", value: "inside" },
]
const STAGE_SIZE_OPTIONS = [
  { label: "kisebb mint 6 x 8", value: "smallerThan6" },
  { label: "6 x 8 és 10 x 10 közt", value: "between6and10" },
  { label: "nagyobb mint 10 x 10", value: "greaterThan10" },
  { label: "belmagasság kisebb mint 6m ", value: "innerHeightSmallerThan6" },
  { label: "belmagasság 6m +", value: "innerHeightGreaterThan6" },
]
const STAGE_TYPE_OPTIONS = [
  { label: "aszfalt", value: "concrete" },
  { label: "fű", value: "grass" },
  { label: "homok", value: "sand" },
  { label: "színpad fa borítással", value: "stageWithWoodFlooring" },
  { label: "színpad balett szőnyeggel", value: "stageWithBalletMat" },
  { label: "parketta", value: "woodFlooring" },
  { label: "egyéb", value: "other" },
]
const PRODUCTION_TYPE_OPTIONS = [
  { label: "városnap, falunap", value: "cityDay" },
  { label: "családi-, sport-, egészségnap", value: "familyDay" },
  { label: "céges party", value: "companyParty" },
  { label: "díjátadó, bál, gála esemény", value: "awards" },
  { label: "majális", value: "picnic" },
  { label: "gyermeknap", value: "childrenDay" },
  { label: "autómentes nap", value: "carFreeDay" },
  { label: "mobilitás hét", value: "mobilityWeek" },
  { label: "advent", value: "advent" },
  { label: "szilveszter", value: "newYearsEve" },
  { label: "zenei fesztivál", value: "festival" },
  { label: "sportverseny, sportbajnokság", value: "competition" },
]
const SHOW_LENGHT_OPTIONS = [
  { label: "5 perc (1x vagy többször)", value: "5min" },
  { label: "8 - 10 perc", value: "between8and10" },
  { label: "12 - 15 perc", value: "between12and15" },
  { label: "25 perc +", value: "moreThan25" },
]
const EMPLOYMENT_OPTIONS = [
  { label: "van", value: "true" },
  { label: "nincs", value: "false" },
]
const TARGET_GROUP_OPTIONS = [
  { label: "gyerekek", value: "children" },
  { label: "felnőttek", value: "adults" },
  { label: "férfiak", value: "men" },
  { label: "nők", value: "women" },
  { label: "idősek", value: "elders" },
]
const PRICE_RANGE_OPTIONS = [
  { label: "alacsony", value: "low" },
  { label: "közepes", value: "medium" },
  { label: "magas", value: "high" },
]

const AltTitleContainer = styled.div`
  max-width: 780px;
  text-align: center;
  margin: 0 auto 3rem auto;
`
const Content = styled.p`
  align-self: center;
`
const Tag = styled.span`
  font-size: 1.5em;
  color: #fff;
  text-transform: uppercase;
  background-color: #1b2840;
  padding: 5px 15px 0 15px;
  display: inline-block;
  letter-spacing: 2px;
  font-family: "Teko", sans-serif;
  line-height: 0.9;
  margin-bottom: 1rem;
`
const Filter = styled.form`
  text-align: center;
`
const Searchfield = styled.input`
  max-width: 610px;
  margin: 0 auto 2rem auto;
  display: block;
  width: 100%;
  color: #fff;
  border: 1px solid #fff;
  background-color: #1a1a1a;
  padding: 0.833333rem 1.3888888889rem;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &:focus {
    outline: none;
  }
`
const Accent = styled.span`
  color: #6c9fff;
`
const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #4d4d4d;

  @media all and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`
const Column = styled.div``
const Button = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  font-size: 1.77778em;
  font-family: "Teko", sans-serif;
  color: #fff;
  padding: 15px 30px 10px 30px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  z-index: 1;
  margin-top: 2.77777778rem;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    background: #fff;
    z-index: -1;
    opacity: 1;
  }
  &:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%
    );
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    color: #000;
    animation-fill-mode: forwards;

    &:before {
      animation: turn-on--outline-before 1s;
    }
    &:after {
      opacity: 1;
      animation-fill-mode: forwards;
    }
  }
`
const LoaderContainer = styled(Container)`
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const filters = {
  title: (c, filter) => c.toLowerCase().includes(filter.toLowerCase()),
  location: (c, filter) => intersection(c, filter).length > 0,
  stageSize: (c, filter) => intersection(c, filter).length > 0,
  stageType: (c, filter) => intersection(c, filter).length > 0,
  productionType: (c, filter) => intersection(c, filter).length > 0,
  showLength: (c, filter) => intersection(c, filter).length > 0,
  employment: (c, filter) => filter.every(f => f === c),
  targetGroup: (c, filter) => intersection(c, filter).length > 0,
  priceRange: (c, filter) => intersection(c, filter).length > 0,
}

const ShowPage = () => {
  const { showBg, showBgResize } = useHeroAssets()
  const shows = useShows()
  const [formValues, setFormValues] = useState({})
  const [filteredShows, setFilteredShows] = useState(shows)
  const [loading, setLoading] = useState(false)

  const titleChangeHandler = event =>
    setFormValues({ ...formValues, title: event.target.value })

  const onChangeHandler = name => value =>
    setFormValues({ ...formValues, [name]: value })

  const onSubmitHandler = event => {
    event.preventDefault()
    setLoading(true)
    if (Object.keys(formValues).every(v => formValues[v] === undefined)) {
      setTimeout(() => {
        setFilteredShows(shows)
        setLoading(false)
      }, 1500)
      return
    }
    setTimeout(() => {
      setFilteredShows(
        shows.filter(show => {
          return Object.keys(formValues)
            .map(filter => {
              if (!formValues[filter]) return true
              return filters[filter](show[filter], formValues[filter])
            })
            .every(v => v)
        })
      )
      setLoading(false)
    }, 1500)
  }
  return (
    <Layout
      heroOptions={{
        title: (
          <>
            Sportbemutatók
            <br /> <Accent>showműsorok</Accent>
          </>
        ),
        bg: showBg,
      }}
    >
      <SEO
        title="Sportbemutatók, show műsorok"
        slug="/sportbemutatok"
        ogImage={showBgResize}
      />
      <Container>
        <AltTitleContainer className="wow fadeIn">
          <Tag>Flipunit production</Tag>
          <Content>
            Használd a szűrőt, hogy könnyebb legyen eligazodni a széles
            választékban, és megtaláld a számodra tökéletes műsort! (Ha kérdésed
            van, hívj minket telefonon és segítünk!)
          </Content>
        </AltTitleContainer>
        <Filter onSubmit={onSubmitHandler}>
          <Searchfield
            placeholder="Mit keresel?"
            onChange={titleChangeHandler}
            value={formValues.title || ""}
            className="wow fadeIn"
          />
          <CheckboxContainer>
            <Column>
              <According
                title="Helyszín"
                options={LOCATION_OPTIONS}
                value={formValues.location}
                onChange={onChangeHandler("location")}
                className="wow fadeIn"
              />
              <According
                title="Színpad/terület mérete (nettó hasznos terület)"
                options={STAGE_SIZE_OPTIONS}
                value={formValues.stageSize}
                onChange={onChangeHandler("stageSize")}
                className="wow fadeIn"
                data-wow-delay=".2s"
              />
              <According
                title="Színpad/terület típusa"
                options={STAGE_TYPE_OPTIONS}
                value={formValues.stageType}
                onChange={onChangeHandler("stageType")}
                className="wow fadeIn"
                data-wow-delay=".4s"
              />
              <According
                title="Rendezvény jellege"
                options={PRODUCTION_TYPE_OPTIONS}
                value={formValues.productionType}
                onChange={onChangeHandler("productionType")}
                className="wow fadeIn"
                data-wow-delay=".6s"
              />
            </Column>
            <Column>
              <According
                title="Műsoridő"
                options={SHOW_LENGHT_OPTIONS}
                value={formValues.showLength}
                onChange={onChangeHandler("showLength")}
                className="wow fadeIn"
              />
              <According
                title="Foglalkoztatás"
                options={EMPLOYMENT_OPTIONS}
                value={formValues.employment}
                onChange={onChangeHandler("employment")}
                className="wow fadeIn"
                data-wow-delay=".2s"
              />
              <According
                title="Célcsoport"
                options={TARGET_GROUP_OPTIONS}
                value={formValues.targetGroup}
                onChange={onChangeHandler("targetGroup")}
                className="wow fadeIn"
                data-wow-delay=".4s"
              />
              <According
                title="Árkategória"
                options={PRICE_RANGE_OPTIONS}
                value={formValues.priceRange}
                onChange={onChangeHandler("priceRange")}
                className="wow fadeIn"
                data-wow-delay=".6s"
              />
            </Column>
          </CheckboxContainer>
          <Button className="wow fadeIn" type="submit">
            Szűrés
          </Button>
        </Filter>
      </Container>
      {loading ? (
        <LoaderContainer>
          <SkewLoader loading={loading} color="#6c9fff" />
        </LoaderContainer>
      ) : (
        <Shows shows={buildCovesSrc(filteredShows)} />
      )}
    </Layout>
  )
}

export default ShowPage
